import React, { useMemo } from 'react';
import { customSectionTypes } from '../EditorFormComponents/helpers/CustomSectionTypes';
import { v2Colors } from '@web-for-marketing/react-ui';
import { Heading } from '@web-for-marketing/react-ui';
import { CustomSectionContent } from '@models/section/customSection';
import { CustomSectionDisplayMode } from '@models/customSection';

const classes = {
    customSectionComponentContainer: {
        minHeight: '10rem',
        width: '100%',
    },
    notConfiguredContainer: {
        backgroundColor: v2Colors.core.cloud,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    notConfiguredHeading: {
        margin: '8rem auto',
    },
};

type CustomSectionProps = CustomSectionContent & {
    lazyLoad?: boolean;
    headerSection?: boolean;
    displayMode?: CustomSectionDisplayMode;
    onClose?: () => void;
};

export function CustomSection({
    component,
    params,
    lazyLoad = false,
    headerSection,
    displayMode = 'default',
    onClose,
}: CustomSectionProps): JSX.Element {
    const CustomSectionComponent = useMemo(() => {
        const sectionType = customSectionTypes.find((sectionType) => sectionType.value === component);
        return (sectionType && sectionType.component) || null;
    }, [component]);

    return CustomSectionComponent ? (
        <div css={classes.customSectionComponentContainer}>
            <CustomSectionComponent
                params={params}
                lazyLoad={lazyLoad}
                headerSection={headerSection}
                displayMode={displayMode}
                onClose={onClose}
            />
        </div>
    ) : (
        <div css={classes.notConfiguredContainer}>
            <Heading variant='h2' color='primary' css={classes.notConfiguredHeading}>
                Custom section not configured
            </Heading>
        </div>
    );
}
