import React, { useState, useEffect, startTransition } from 'react';
import { Button, ButtonProps, Dialog } from '@mui/material';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { CustomSection } from '@sections/CustomSection';
import { PopoutDisplayMode } from '@models/customSection';
import { FormParams } from '@models/form';
import { CloseIcon } from '@icon/CloseIcon';
import { v2Colors, breakpoints, DefaultV2ButtonProps, V2Button, IconButton } from '@web-for-marketing/react-ui';
import { visuallyHidden } from '@mui/utils';
import { ClassNames } from '@emotion/react';

const classes = {
    fixedPopout: {
        boxShadow: 'none',
        backgroundColor: v2Colors.core.snow,
        [`@media (max-width: ${breakpoints.md})`]: {
            bottom: 'auto',
            top: 0,
            height: '100% !important',
        },
    },
    popoutBackground: (backgroundImagePath: string) => ({
        boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.08)',
        background: `url(${backgroundImagePath}) no-repeat top / cover`,
        [`@media (max-width: ${breakpoints.lg})`]: {
            backgroundPosition: 'top left',
        },
        [`@media (max-width: ${breakpoints.sm})`]: {
            backgroundPosition: 'center',
        },
    }),
    popoutPaper: {
        position: 'unset',
        minWidth: '599.9px',
        [`@media (max-width: ${breakpoints.md})`]: {
            margin: '1.6rem',
            minWidth: 'unset',
        },
    },
    popoutContentContainer: {
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
} as const;

interface BaseProps {
    text: string;
    customSectionKey?: string;
    displayMode?: PopoutDisplayMode;
    backgroundImagePath?: string;
    closeAdditional?: () => void;
    params?: FormParams;
}

interface MUIButtonProps extends ButtonProps {
    isV2?: false;
}

interface V2ButtonProps extends Omit<DefaultV2ButtonProps, 'children'> {
    isV2: true;
}

type PopoutButtonProps = BaseProps & (MUIButtonProps | V2ButtonProps);

export function PopoutButton({
    text,
    customSectionKey,
    displayMode,
    backgroundImagePath,
    params = [],
    closeAdditional,
    isV2,
    ...buttonProps
}: PopoutButtonProps): JSX.Element {
    const { prefixStringWithBaseUrl } = useStaticValues();
    const [isMobile] = useAtom(isMobileAtom);
    const [open, setOpen] = useState(false);

    const setPopoutOpen = (popoutValue: boolean): void => {
        if (closeAdditional) closeAdditional();
        startTransition(() => {
            setOpen(popoutValue);
        });
    };

    const handleClose = (): void => {
        setPopoutOpen(false);
    };

    useEffect(() => {
        if (open) {
            setPopoutOpen(false);
        }
        return () => {
            if (open) {
                setPopoutOpen(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <>
            {isV2 ? (
                <V2Button
                    aria-label={`Open ${text} popout`}
                    onClick={() => setPopoutOpen(true)}
                    {...(buttonProps as DefaultV2ButtonProps)}
                >
                    {text}
                </V2Button>
            ) : (
                <Button
                    aria-label={`Open ${text} popout`}
                    onClick={() => setPopoutOpen(true)}
                    {...(buttonProps as ButtonProps)}
                >
                    {text}
                </Button>
            )}
            {open ? (
                displayMode === 'bg-popout' ? (
                    <ClassNames>
                        {({ css }) => (
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                css={
                                    backgroundImagePath &&
                                    classes.popoutBackground(prefixStringWithBaseUrl(backgroundImagePath))
                                }
                                classes={{ paper: css(classes.popoutPaper) }}
                                aria-labelledby='popout-button-title'
                            >
                                <h2 style={visuallyHidden} id='popout-button-title'>
                                    {text}
                                </h2>
                                <CustomSection
                                    component={customSectionKey}
                                    params={params}
                                    lazyLoad={false}
                                    displayMode={displayMode}
                                    onClose={handleClose}
                                />
                            </Dialog>
                        )}
                    </ClassNames>
                ) : (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        hideBackdrop
                        fullScreen
                        css={classes.fixedPopout}
                        aria-labelledby='popout-button-title'
                    >
                        <h2 style={visuallyHidden} id='popout-button-title'>
                            {text}
                        </h2>
                        <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                label='Close dialog'
                                variant='secondary'
                                size='small'
                                shape='round'
                                css={{ margin: '1.5rem' }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div css={classes.popoutContentContainer}>
                            <CustomSection
                                component={customSectionKey}
                                params={params}
                                lazyLoad={false}
                                displayMode='full-popout'
                                onClose={handleClose}
                            />
                        </div>
                    </Dialog>
                )
            ) : null}
        </>
    );
}
